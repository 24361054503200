import EncryptUtility from "../../utility/js/encrypt-utility.js";
import AddFavourite from "../../utility/js/addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/printer-details";
import shippingService from "./shipping-service.js";
import addressComp from "../address-comp.vue";
import Utility from "../../../shared/utility";
export default {
  data() {
    return {
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      search: "",
      lpnText: "",
      displayLPN: "",
      tracking: "",
      unitText: "",
      returnTracking: "",
      disableSearch: false,
      showCustomerAddress: false,
      showLPNInfo: false,
      disableCustomer: true,
      addressFlag: false,
      addressFlagPO: false,
      showBoxDialog: false,
      showTracking: false,
      seeMore: true,
      showReturnSerial: false,
      confirmationDialog: false,
      editJSON: "",
      unitInfoData: [],
      lpnHeader: [
        { text: "LPN", align: "start", value: "lpn" },
        { text: "LPN Status", value: "lpn_status" },
        { text: "Qty", value: "qty" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      lineDataHeader: [
        { text: "Line#", align: "start", value: "line_num" },
        { text: "Type", value: "line_type" },
        { text: "Part#", value: "part_num" },
        { text: "Description", value: "part_desc" },
        { text: "Warehouse", value: "ware" },
        { text: "Location", value: "loc" },
        { text: "Order Qty", value: "tot_qty" },
        { text: "Boxed", value: "boxed" },
        { text: "Shipped", value: "shipped" },
      ],
      activeBoxHeader: [
        { text: "Box", align: "start", value: "lpn" },
        { text: "Box Qty", value: "lpn_status" },
        { text: "Last Activity", value: "qty" },
        { text: "Box Status", value: "boxed" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      unitBoxHeader: [
        { text: "Select Box", value: "SelectBox", align: "start" },
        { text: "Clear Box", value: "ClearBox" },
        { text: "Box", value: "box_no" },
        { text: "Box Qty", value: "qty" },
        { text: "Last Activity", value: "last_scan" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      lpnListData: [],
      lineData: [],
      searchData: {
        HeaderInfo: [
          {
            status: "",
            message: "",
            set_ui: 0,
          },
        ],
        Order_Info: [
          {
            order_key: "",
            ordertype: "",
            order_type: "",
            ordno: "",
            sl_description: "",
            sl_code: "",
            ship_to: "",
            ship_to_add: "",
            custpo_vendso: "",
            sl_descrip_rtn: "",
            sl_code_rtn: "",
            proj_code: "",
            bu_code: "",
            order_note: "",
            rma: "",
            lpn_active: false,
            getWS_OB_WBN: false,
            getWS_IB_WBN: false,
            require_rtn_wbn: false,
            carrier_key: 0,
            carrier_short_name: "",
          },
        ],
        Line_Info: [
          {
            line_num: "",
            part_num: "",
            part_desc: "",
            ware: "",
            loc: "",
            tot_qty: "",
            boxed: "",
            shipped: "",
            line_id: "",
            linetype_key: "",
            line_type: "",
          },
        ],
        LPNorBox_Info: [
          {
            lpn_id: "",
            lpn: "",
            lpn_status: "",
            waybill: "",
            qty: "",
            enable_bit: "",
          },
        ],
      },
      lpnData: {
        so_key: 0,
        line_key: 0,
        weight: 0,
        cost: 0,
        box_h: 0,
        box_w: 0,
        box_l: 0,
        sotype_key: 0,
        reqrtn_wbn: false,
        box_id: 0,
      },
      selectedLabel: "",
      itemsLabel: [],
      showOtherSelected: false,
      selectedPackage: [],
      hideVerifyDim: true,
      required: [(v) => !!v || "Field is required"],
      boxingUnitsData: [],
      boxingUnitsHeader: [
        {
          text: "Box",
          align: "start",
          value: "box",
        },
        {
          text: "Part#",
          value: "part",
        },
        {
          text: "BCN/Asset Tag",
          value: "bcn",
        },
        {
          text: "Serial#",
          value: "serialno",
        },
        {
          text: "Qty",
          value: "qty",
        },
      ],
      completedBoxHeader: [
        {
          text: "Box No",
          align: "start",
          value: "box_no",
        },
        {
          text: "Quantity",
          value: "qty",
        },
        {
          text: "Last Scan",
          value: "last_scan",
        },
        {
          text: "Waybill",
          value: "waybill",
        },
      ],
      completedBoxesData: [],
      selectedWarehouse: "",
      warehouseTypeList: [],
      selectedLocation: "",
      locationList: [],
      totalOpenQty: 0,
      binHeader: [
        {
          text: "Bin",
          align: "start",
          value: "bin",
        },
        {
          text: "Grade",
          value: "cos_grade",
        },
        {
          text: "Available",
          value: "avail",
        },
      ],
      binData: [],
      totalShipped: 0,
      lpnSearched: false,
      secondaryLabelSize: "",
      secondaryPrinterType: "",
      showSubmit: false,
      disableSubmitBtn: false,
      showFreight: false,
      showLabels: false,
      locationToShipDialog: false,
      unitData: [],
      warehouseDisabled: false,
      locationDisabled: true,
      disableSubmitLocToShip: true,
      shipQuantity: 0,
      max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character", (v) => !!v || "Field is required"],
      disableUnitIdentifier: true,
      showCompletedBoxes: false,
      unitInfoSearched: false,
      shippingVerifyData: [],
      shippingVerifyDataIn: [],
      locToShipBoxId: 0,
      disableTrackingWaybill: false,
      showTotalShipped: false,
      showShipQuantity: false,
      showBinDetails: false,
      showOpenQty: false,
      showLocationDetails: false,
      searchLPNData: [],
      flowDirection: "",
      disableSelectedBox: false,
      showAddBox: true,
      shippingAddress: [],
      showRefreshDialog: false,
      secondaryName: "",
      defaultName: "",
      boxKey: 0,
      submitWaybill: "",
      submitRtnWaybill: "",
      submitFreight: 0,
      freight: 0,
      selPackageOut: "",
      selPackageIn: "",
      returnLPNData: {
        weight: 0,
        box_h: 0,
        box_w: 0,
        box_l: 0,
      },
      directLPNData: {
        weight: 0,
        box_h: 0,
        box_w: 0,
        box_l: 0,
      },
      printerAvlbl: false,
      printerConfigured: false,
      showPrimary: false,
      debouncedValidateTracking: [],
      debouncedRetValidateTracking: [],
      valid: false,
      retValid: false,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.search = atob(this.$route.params.id);
    if (this.search !== null && this.search !== undefined) {
      this.onSearchClick(this.search);
    } else {
      this.$router.go(-1);
    }
    if (EncryptUtility.localStorageDecrypt("defaultName") !== null && EncryptUtility.localStorageDecrypt("defaultName") !== undefined) {
      if (
        EncryptUtility.localStorageDecrypt("secondaryLabelSize") !== null &&
        EncryptUtility.localStorageDecrypt("secondaryLabelSize") !== undefined
      ) {
        if (
          EncryptUtility.localStorageDecrypt("secondaryPrinterType") !== null &&
          EncryptUtility.localStorageDecrypt("secondaryPrinterType") !== undefined
        ) {
          this.secondaryLabelSize = EncryptUtility.localStorageDecrypt("secondaryLabelSize");
          this.secondaryPrinterType = EncryptUtility.localStorageDecrypt("secondaryPrinterType");
          this.secondaryName = EncryptUtility.localStorageDecrypt("secondaryName");
          this.lpnSearched = false;
          this.printerAvlbl = true;
          this.printerConfigured = false;
          this.disableUnitIdentifier = false;
        }
      } else {
        this.lpnSearched = true;
        this.printerAvlbl = false;
        this.printerConfigured = true;
        this.disableUnitIdentifier = true;
        let Alert = {
          type: "error",
          isShow: true,
          message: "Shipping Printer is not configured, please configure printer.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    } else {
      this.lpnSearched = true;
      this.printerAvlbl = false;
      this.printerConfigured = true;
      this.disableUnitIdentifier = true;
      let Alert = {
        type: "error",
        isShow: true,
        message: "Default Printer is not configured, please configure printer.",
      };
      this.$store.commit("ConfigModule/Alert", Alert);
    }
    this.debouncedValidateTracking = Utility.debounce(this.validateTracking, 500);
    this.debouncedRetValidateTracking = Utility.debounce(this.retValidateTracking, 500);
  },
  mounted() {},
  computed: {
    formattedText() {
      if (this.selectedPackage[0]?.OptionsDesc && this.selectedPackage[0]?.OptionsDesc.indexOf("||") !== -1) {
        const options = this.selectedPackage[0].OptionsDesc.split("||", 5);
        let formattedText = "";
        for (let i = 0; i < Math.min(options.length, 4); i++) {
          formattedText += options[i] + "<br />";
        }
        return formattedText.slice(0, -2);
      } else {
        return this.selectedPackage[0]?.OptionsDesc;
      }
    },
  },
  methods: {
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    //Reset Function
    resetFunction() {
      this.disableSearch = false;
      this.showCustomerAddress = false;
      this.addressFlag = false;
      this.addressFlagPO = false;
      this.showBoxDialog = false;
      this.showLPNInfo = false;
      this.showTracking = false;
      this.showReturnSerial = false;
      this.search = "";
      this.lpnText = "";
      this.searchData = {
        HeaderInfo: [
          {
            status: "",
            message: "",
            set_ui: 0,
          },
        ],
        Order_Info: [
          {
            order_key: "",
            ordertype: "",
            order_type: "",
            ordno: "",
            sl_description: "",
            sl_code: "",
            ship_to: "",
            ship_to_add: "",
            custpo_vendso: "",
            sl_descrip_rtn: "",
            sl_code_rtn: "",
            proj_code: "",
            bu_code: "",
            order_note: "",
            rma: "",
            lpn_active: false,
            getWS_OB_WBN: false,
            getWS_IB_WBN: false,
            require_rtn_wbn: false,
            carrier_key: 0,
            carrier_short_name: "",
          },
        ],
        Line_Info: [
          {
            line_num: "",
            part_num: "",
            part_desc: "",
            ware: "",
            loc: "",
            tot_qty: "",
            boxed: "",
            shipped: "",
            line_id: "",
            linetype_key: "",
            line_type: "",
          },
        ],
        LPNorBox_Info: [
          {
            lpn_id: "",
            lpn: "",
            lpn_status: "",
            waybill: "",
            qty: "",
            enable_bit: "",
          },
        ],
      };
      this.lpnListData = [];
      this.lineData = [];
      this.lpnData = {
        so_key: 0,
        line_key: 0,
        weight: 0,
        cost: 0,
        box_h: 0,
        box_w: 0,
        box_l: 0,
        sotype_key: 0,
        reqrtn_wbn: false,
        box_id: 0,
      };
      this.selectedLabel = "";
      this.itemsLabel = [];
      this.showOtherSelected = false;
      this.selectedPackage = [];
      this.hideVerifyDim = true;
      this.boxingUnitsData = [];
      this.selectedWarehouse = "";
      this.warehouseTypeList = [];
      this.selectedLocation = "";
      this.locationList = [];
      this.totalOpenQty = 0;
      this.binData = [];
      this.totalShipped = 0;
      this.unitInfoData = 0;
      this.lpnSearched = false;
      this.secondaryLabelSize = "";
      this.secondaryPrinterType = "";
      this.showSubmit = false;
      this.disableSubmitBtn = false;
      this.showFreight = false;
      this.showLabels = false;
      this.locationToShipDialog = false;
      this.unitData = [];
      this.warehouseDisabled = false;
      this.locationDisabled = true;
      this.disableSubmitLocToShip = true;
      this.shipQuantity = 0;
      this.disableUnitIdentifier = true;
      this.unitText = "";
      this.completedBoxesData = [];
      this.showCompletedBoxes = false;
      this.unitInfoSearched = false;
      this.shippingVerifyData = [];
      this.shippingVerifyDataIn = [];
      this.locToShipBoxId = 0;
      this.disableTrackingWaybill = false;
      this.showTotalShipped = false;
      this.showShipQuantity = false;
      this.showBinDetails = false;
      this.showOpenQty = false;
      this.showLocationDetails = false;
      this.searchLPNData = [];
      this.flowDirection = "";
      this.disableSelectedBox = false;
      this.showAddBox = true;
      this.showRefreshDialog = false;
      this.secondaryName = "";
      this.boxKey = 0;
      this.submitWaybill = "";
      this.submitRtnWaybill = "";
      this.submitFreight = 0;
      this.freight = 0;
      this.selPackageOut = "";
      this.selPackageIn = "";
      this.returnLPNData = [];
      this.directLPNData = [];
      this.printerAvlbl = false;
      this.printerConfigured = false;
      if (this.unitInfoSearched) {
        this.$refs.unitSearchForm.resetValidation();
      }
      if (this.showLPNInfo) {
        this.$refs.packagingAddForm.resetValidation();
      }
      if (this.showReturnSerial) {
        this.$refs.retTrackingWaybillValidateForm.resetValidation();
      }
    },
    //Search button click event
    async onSearchClick(text) {
      let obj = {
        lpn: "",
        pass: 1,
        ordertype: "",
        order_key: parseInt(text),
        UserId: this.userId,
        waybill: "",
        return_wbn: 0,
        lbl_size: this.secondaryLabelSize,
        printer_type: this.secondaryPrinterType,
      };
      let res = await shippingService.getSearchShipFulData("post", obj);
      if (res !== undefined) {
        if (res.message !== "NA") {
          this.searchData = JSON.parse(JSON.stringify(res.Result));
          this.shippingAddress = this.searchData.Order_Info[0]?.ship_to_add.split("||");
          this.disableSearch = true;
          if (this.searchData.Order_Info[0]?.lpn_active) {
            this.unitInfoSearched = false;
            if (this.searchData.LPNorBox_Info.length !== 0) {
              this.lpnListData = JSON.parse(JSON.stringify(this.searchData.LPNorBox_Info));
            }
          } else {
            this.unitInfoSearched = true;
            if (this.searchData.LPNorBox_Info.length !== 0) {
              let searchData = JSON.parse(JSON.stringify(this.searchData.LPNorBox_Info));
              const newArr = searchData.map((obj) => {
                return {
                  ...obj,
                  last_scan: Utility.convertESTToLocal(obj.last_scan),
                };
              });
              this.unitInfoData = newArr;
              this.disableSelectedBox = false;
            } else {
              this.disableUnitIdentifier = false;
            }
          }
          this.lineData = JSON.parse(JSON.stringify(this.searchData.Line_Info));
        }
      }
    },
    //Edit Customer Address
    async customerAddress() {
      if (this.searchData.Order_Info[0]?.ordertype == "S") {
        let obj = {
          pass: 1,
          so_key: this.searchData.Order_Info[0]?.order_key,
          user_key: this.userId,
        };
        let object = { InJson: JSON.stringify(obj) };
        let res = await shippingService.getSoAddress("post", object);
        if (res !== undefined) {
          if (res.message !== "NA") {
            this.editJSON = res.Result;
            this.addressFlag = true;
          }
        }
      } else {
        let obj = {
          pass: 1,
          po_key: this.searchData.Order_Info[0]?.order_key,
          user_key: this.userId,
        };
        let object = { InJson: JSON.stringify(obj) };
        let res = await shippingService.getPoAddress("post", object);
        if (res !== undefined) {
          if (res.message !== "NA") {
            this.editJSON = res.Result;
            this.addressFlagPO = true;
          }
        }
      }
      this.showCustomerAddress = true;
    },
    // Cancel Customer dialog
    cancelCustomerAddress() {
      this.showCustomerAddress = false;
      this.addressFlag = false;
      this.addressFlagPO = false;
    },
    //Adding a new Address
    async addAddress(obj, val) {
      if (val == "updateCustAddress") obj["pass"] = 2;
      this.searchData.Order_Info[0]?.ordertype == "S"
        ? (obj["so_key"] = this.searchData.Order_Info[0]?.order_key)
        : (obj["po_key"] = this.searchData.Order_Info[0]);
      obj["user_key"] = this.userId;
      let object = { InJson: JSON.stringify(obj) };

      (await this.searchData.Order_Info[0]?.ordertype) == "S"
        ? shippingService.getSoAddress("post", object, true)
        : shippingService.getPoAddress("post", object, true);
      this.addressFlag = false;
      this.addressFlagPO = false;
      this.showCustomerAddress = false;
    },

    //LPN Flow - Start
    //Ship text click event & search event for button click of search
    async searchLPN(text) {
      if (text == "") {
        this.lpnSearched = false;
      } else {
        let obj = {
          lpn: text,
          pass: 2,
          ordertype: this.searchData.Order_Info[0]?.ordertype,
          order_key: this.searchData.Order_Info[0]?.order_key,
          UserId: this.userId,
          waybill: "",
          return_wbn: 0,
          lbl_size: this.secondaryLabelSize,
          printer_type: this.secondaryPrinterType,
        };
        let res = await shippingService.getSearchShipFulData("post", obj);
        if (res !== undefined) {
          if (res.message !== "NA") {
            if (res.Result?.HeaderInfo.length !== 0) {
              if (res.Result?.HeaderInfo[0]?.IsBulk) {
                if (res.Result.HeaderInfo[0]?.bulk_label !== "") {
                  PrinterDetails.printLabelData(res.Result.HeaderInfo[0]?.bulk_label, this.secondaryName);
                } else {
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: "There is no Bulk Label to print",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
                if (res.Result?.HeaderInfo[0]?.set_ui == 2) {
                  this.resetFunction();
                  this.$router.go(-1);
                }
              } else {
                if (res.Result.Dim_Info.length !== 0) {
                  this.searchLPNData = [];
                  this.searchLPNData = res.Result;
                  this.$refs.lpnSearchForm.resetValidation();
                  this.lpnData = JSON.parse(JSON.stringify(this.searchLPNData.Dim_Info[0]));
                  this.showLPNInfo = true;
                  let lpnDet = this.searchData.LPNorBox_Info.filter((obj) => obj.lpn_id == this.searchLPNData.HeaderInfo[0].lpn_id);
                  this.displayLPN = lpnDet[0].lpn;
                  this.itemsLabel = [];
                  this.itemsLabel = this.searchLPNData.Package_Info_Out;
                  if (this.itemsLabel.length == 1) {
                    this.selectedLabel = this.itemsLabel[0].OptionsCode;
                    this.labelSelected();
                  }
                  this.showReturnSerial = false;
                  this.flowDirection = "OUT";
                  this.lpnSearched = true;
                } else {
                  var Alert = {
                    type: "error",
                    isShow: true,
                    message: "There is no Dimension information",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                  this.lpnSearched = false;
                }
              }
            }
          }
        }
      }
    },
    //LPN Flow - End

    //Unit Flow - Start
    //Unit Info Search field
    async searchUnit(text) {
      if (this.$refs.unitSearchForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let obj = {
          pass: 1,
          po_so: this.searchData.Order_Info[0]?.ordertype,
          user_key: parseInt(this.userId),
          ord_key: parseInt(this.searchData.Order_Info[0]?.order_key),
          unit: text,
          box_key: 0,
          rware_key: 0,
          XML: "",
          packslip: "",
          waybill: "",
          cusrtn_wbn: "",
          weight: 0,
          length: 0,
          width: 0,
          height: 0,
          box_no: 0,
          line_id: 0,
          lpn: "",
        };
        this.axios
          .post("sh/shipping_details", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.refreshLocation();
              let data = JSON.parse(response.data.body.message);
              if (data !== "" && data !== undefined) {
                this.unitData = data.Result;
                this.disableUnitIdentifier = true;
                if (this.unitData?.HeaderInfo[0].partbit) {
                  this.locationToShipDialog = true;
                  this.warehouseTypeList = this.unitData?.Ware_Info;
                  this.locationList = this.unitData?.Ware_Info;
                  this.totalOpenQty = this.unitData?.Ware_Info[0].open_qty;
                  this.binData = this.unitData?.Ware_Info;
                } else {
                  this.locationToShipDialog = false;
                  this.unitInfoData = JSON.parse(JSON.stringify(this.unitData?.LPNorBox_Info));
                }
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              let searchData = JSON.parse(JSON.stringify(this.searchData.LPNorBox_Info));
              const newArr = searchData.map((obj) => {
                return {
                  ...obj,
                  last_scan: Utility.convertESTToLocal(obj.last_scan),
                };
              });
              this.unitInfoData = newArr;
              this.disableSelectedBox = false;
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            let searchData = JSON.parse(JSON.stringify(this.searchData.LPNorBox_Info));
            const newArr = searchData.map((obj) => {
              return {
                ...obj,
                last_scan: Utility.convertESTToLocal(obj.last_scan),
              };
            });
            this.unitInfoData = newArr;
            this.disableSelectedBox = false;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Adding a new Active Box
    submitLocToShip() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        pass: 2,
        po_so: this.searchData.Order_Info[0]?.ordertype,
        user_key: parseInt(this.userId),
        ord_key: parseInt(this.searchData.Order_Info[0]?.order_key),
        unit: this.unitText,
        box_key: parseInt(this.locToShipBoxId),
        rware_key: parseInt(this.selectedWarehouse),
        XML: "",
        packslip: "",
        waybill: "",
        cusrtn_wbn: "",
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        box_no: 0,
        line_id: 0,
        lpn: "",
        binsJson: JSON.stringify({
          loc: this.selectedLocation,
          bin: {
            binKey: this.unitData.Ware_Info[0].bin_key,
            qty: parseInt(this.shipQuantity),
            cg: this.unitData.Ware_Info[0].cos_grade,
          },
        }),
      };
      this.axios
        .post("sh/shipping_details", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            let resultData = JSON.parse(JSON.stringify(responseData.Result));
            this.lineData = JSON.parse(JSON.stringify(resultData.Line_Info));
            let headerData = JSON.parse(JSON.stringify(resultData.HeaderInfo));
            if (headerData[0]?.set_ui == 1) {
              this.showAddBox = false;
            } else if (headerData[0]?.set_ui == 0) {
              this.showAddBox = true;
            } else if (headerData[0]?.set_ui == 2) {
              this.resetFunction();
            }
            if (resultData.LPNorBox_Info.length !== 0) {
              let searchData = JSON.parse(JSON.stringify(resultData.LPNorBox_Info));
              if (searchData.length !== 0) {
                const newArr = searchData.map((obj) => {
                  return {
                    ...obj,
                    last_scan: Utility.convertESTToLocal(obj.last_scan),
                  };
                });
                this.unitInfoData = newArr;
                this.disableSelectedBox = false;
              }
            }
            this.closeDialog();
            let Alert = {
              type: "success",
              isShow: true,
              message: headerData[0].message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Add new empty row in Unit data table
    addBox() {
      this.disableUnitIdentifier = false;
      this.unitText = "";
      const hasEmptyArray = this.unitInfoData.some((box) => Object.keys(box).length === 0);
      if (!hasEmptyArray) {
        this.unitInfoData.push({});
      }
      this.locToShipBoxId = 0;
      this.disableSelectedBox = true;
      this.refreshLocation();
    },
    //Select Box hyperlink in Unit Flow
    selectBox(boxId) {
      this.locToShipBoxId = 0;
      this.locToShipBoxId = boxId;
      this.disableSelectedBox = true;
      this.unitInfoData = this.unitInfoData.filter((data) => data.box_id !== boxId);
      this.unitText = "";
      this.disableUnitIdentifier = false;
    },
    //Clear Box hyperlink click event
    clearBox(item) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        pass: 95,
        po_so: this.searchData.Order_Info[0]?.ordertype,
        user_key: parseInt(this.userId),
        ord_key: parseInt(this.searchData.Order_Info[0]?.order_key),
        unit: "",
        box_key: 0,
        rware_key: 0,
        XML: "",
        packslip: "",
        waybill: "",
        cusrtn_wbn: "",
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        box_no: parseInt(item.box_no),
        line_id: 0,
        lpn: "",
      };
      this.axios
        .post("sh/shipping_details", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            this.$refs.unitSearchForm.resetValidation();
            let Alert = {
              type: "success",
              isShow: true,
              message: responseData.Result.HeaderInfo[0].message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            let resultData = responseData.Result;
            this.lineData = resultData.Line_Info;
            if (resultData.HeaderInfo[0]?.set_ui == 1) {
              this.showAddBox = false;
              this.disableUnitIdentifier = true;
              this.unitText = "";
            } else if (resultData.HeaderInfo[0]?.set_ui == 0) {
              this.showAddBox = true;
              this.disableUnitIdentifier = false;
              this.unitText = "";
            } else if (resultData.HeaderInfo[0]?.set_ui == 2) {
              this.resetFunction();
            }
            if (resultData.LPNorBox_Info.length !== 0) {
              let searchData = JSON.parse(JSON.stringify(resultData.LPNorBox_Info));
              if (searchData.length !== 0) {
                const newArr = searchData.map((obj) => {
                  return {
                    ...obj,
                    last_scan: Utility.convertESTToLocal(obj.last_scan),
                  };
                });
                this.unitInfoData = newArr;
                this.disableSelectedBox = false;
              }
            } else {
              this.unitInfoData = [];
            }
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Search Ship value
    async searchShip(boxId) {
      this.locToShipBoxId = boxId;
      let obj = {
        pass: 89,
        po_so: this.searchData.Order_Info[0]?.ordertype,
        user_key: parseInt(this.userId),
        ord_key: 0,
        unit: "",
        box_key: parseInt(boxId),
        rware_key: 0,
        XML: "",
        packslip: "",
        waybill: "",
        cusrtn_wbn: "",
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        box_no: 0,
        line_id: 0,
        lpn: "",
      };
      let showMessage = false;
      let res = await shippingService.shippingDetails("post", obj, showMessage);
      if (res !== undefined) {
        if (res.message !== "NA") {
          this.searchLPNData = [];
          this.searchLPNData = res.Result;
          this.showLPNInfo = true;
          this.lpnData.weight = this.searchLPNData.HeaderInfo[0].weight;
          this.lpnData.box_h = this.searchLPNData.HeaderInfo[0].height;
          this.lpnData.box_w = this.searchLPNData.HeaderInfo[0].width;
          this.lpnData.box_l = this.searchLPNData.HeaderInfo[0].length;
          this.flowDirection = "";
          this.itemsLabel = [];
          this.itemsLabel = this.searchLPNData.Package_Info_Out;
          if (this.itemsLabel.length == 1) {
            this.selectedLabel = this.itemsLabel[0].OptionsCode;
            this.labelSelected();
          }
          this.showReturnSerial = false;
          this.flowDirection = "OUT";
        }
      }
    },
    //Quantity to ship check
    quantityToShip() {
      if (this.shipQuantity > this.unitData.Ware_Info[0].avail) {
        var Alert = {
          type: "error",
          isShow: true,
          message: "Please select based on the Available Quantity",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      } else {
        this.totalShipped = parseInt(this.shipQuantity);
        this.disableSubmitLocToShip = false;
        this.showTotalShipped = true;
      }
    },
    //Hyper link in the Active Boxes Box Qty value
    async boxQtyLink(item) {
      let obj = {
        pass: 90,
        po_so: this.searchData.Order_Info[0]?.ordertype,
        user_key: parseInt(this.userId),
        ord_key: 0,
        unit: "",
        box_key: parseInt(item.box_id),
        rware_key: 0,
        XML: "",
        packslip: "",
        waybill: "",
        cusrtn_wbn: "",
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        box_no: parseInt(item.box_no),
        line_id: 0,
        lpn: "",
      };
      let showMessage = false;
      let data = await shippingService.shippingDetails("post", obj, showMessage);
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.boxingUnitsData = data.Result;
          this.showCompletedBoxes = false;
          this.showBoxDialog = true;
        }
      }
    },
    //View Completed Boxes button
    completedBoxes() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        pass: 4,
        po_so: this.searchData.Order_Info[0]?.ordertype,
        user_key: parseInt(this.userId),
        ord_key: parseInt(this.searchData.Order_Info[0]?.order_key),
        unit: "",
        box_key: 0,
        rware_key: 0,
        XML: "",
        packslip: "",
        waybill: "",
        cusrtn_wbn: "",
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        box_no: 0,
        line_id: 0,
        lpn: "",
      };
      this.axios
        .post("sh/shipping_details", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            if (responseData.Result.LPNorBox_Info.length !== 0) {
              const newArr = responseData.Result.LPNorBox_Info.map((obj) => {
                return {
                  ...obj,
                  last_scan: Utility.convertESTToLocal(obj.last_scan),
                };
              });
              this.completedBoxesData = newArr;
              this.showCompletedBoxes = true;
              this.showBoxDialog = true;
              let Alert = {
                type: "success",
                isShow: true,
                message: responseData.Result.HeaderInfo[0].message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              var Alert = {
                type: "error",
                isShow: true,
                message: "There are no completed boxes",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Box Line details of Line Data in Unit Flow
    async lineBoxDetails() {
      let obj = {
        pass: 90,
        po_so: this.searchData.Order_Info[0]?.ordertype,
        user_key: parseInt(this.userId),
        ord_key: 0,
        unit: "",
        box_key: 0,
        rware_key: 0,
        XML: "",
        packslip: "",
        waybill: "",
        cusrtn_wbn: "",
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        box_no: 0,
        line_id: parseInt(this.lineData[0].line_id),
        lpn: "",
      };
      let showMessage = true;
      let data = await shippingService.shippingDetails("post", obj, showMessage);
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.boxingUnitsData = data.Result;
          this.showCompletedBoxes = false;
          this.showBoxDialog = true;
        }
      }
    },
    //Unit Flow - End

    //Warehouse Selected
    warehouseSelected() {
      this.locationDisabled = false;
      this.warehouseDisabled = true;
      this.showLocationDetails = true;
    },
    //Location Selected
    locationSelected() {
      this.locationDisabled = true;
      this.showOpenQty = true;
      this.showBinDetails = true;
      this.showShipQuantity = true;
    },
    //Label in Box and Dims popup
    labelSelected() {
      if (this.selectedLabel == "Other") {
        this.showOtherSelected = true;
        this.selectedPackage = [];
      } else {
        this.showOtherSelected = false;
        this.selectedPackage = this.itemsLabel.filter((ele) => ele.OptionsCode == this.selectedLabel);
      }
    },
    //Refresh for the location popup
    refreshLocation() {
      this.warehouseDisabled = false;
      this.locationDisabled = true;
      this.disableSubmitLocToShip = true;
      this.selectedWarehouse = "";
      this.selectedLocation = "";
      this.totalOpenQty = 0;
      this.totalShipped = 0;
      this.shipQuantity = 0;
      this.binData = [];
      this.binData = this.unitData.Ware_Info;
      this.showTotalShipped = false;
      this.showShipQuantity = false;
      this.showBinDetails = false;
      this.showOpenQty = false;
      this.showLocationDetails = false;
      if (this.locationToShipDialog) {
        this.$refs.locationForm.resetValidation();
      }
    },
    //Verify Dims button click event
    verifyDims() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      if (this.flowDirection == "OUT") {
        if (this.$refs.packagingAddForm.validate()) {
          this.hideVerifyDim = false;
          this.directLPNData = this.lpnData;
          if (this.selectedLabel !== null || this.selectedLabel !== undefined || this.selectedLabel !== "") {
            if (this.selectedLabel !== "Other") {
              this.lpnData.box_l = 0;
              this.lpnData.box_w = 0;
              this.lpnData.box_h = 0;
              this.directLPNData.box_l = 0;
              this.directLPNData.box_w = 0;
              this.directLPNData.box_h = 0;
            }
            this.selPackageOut = "";
            this.selPackageOut = this.selectedLabel;
          }
          if (this.searchData.Order_Info[0]?.getWS_OB_WBN) {
            if (this.searchData.Order_Info[0]?.carrier_short_name == "UPS" || this.searchData.Order_Info[0]?.carrier_short_name == "FedEx") {
              if (!this.unitInfoSearched) {
                this.boxKey = parseInt(this.searchLPNData.HeaderInfo[0].lpn_id == "" ? 0 : this.searchLPNData.HeaderInfo[0].lpn_id);
              } else {
                this.boxKey = parseInt(this.locToShipBoxId);
              }
              let obj = {
                po_so: this.searchData.Order_Info[0]?.ordertype,
                UserId: parseInt(this.userId),
                ord_key: this.searchData.Order_Info[0]?.order_key,
                box_key: this.boxKey,
                weight: parseFloat(this.lpnData.weight),
                length: parseFloat(this.lpnData.box_l),
                width: parseFloat(this.lpnData.box_w),
                height: parseFloat(this.lpnData.box_h),
                carrierCode: this.searchData.Order_Info[0]?.carrier_short_name,
                direction: "OUT",
                lbl_size: this.secondaryLabelSize,
                printer_brand: this.secondaryPrinterType,
                packaging_code: this.selectedLabel,
              };
              this.axios
                .post("sh/shipping_initiate", obj)
                .then((response) => {
                  if (response.status == 200 || response.status == 204) {
                    LoaderDialog.visible = false;
                    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                    let responseData = JSON.parse(response.data.body.message);
                    this.hideVerifyDim = false;
                    this.showTracking = true;
                    this.shippingVerifyData = [];
                    this.shippingVerifyData = responseData;
                    this.submitFreight = parseFloat(this.shippingVerifyData?.freight);
                    this.showLabels = true;
                    this.showTracking = false;
                    this.showFreight = false;
                    this.showReturnSerial = false;
                    this.showSubmit = false;
                    this.disableSubmitBtn = true;
                    this.flowDirection = "IN";
                    this.confirmationDialog = true;
                    this.closeDialog();
                    this.itemsLabel = [];
                    this.itemsLabel = this.searchLPNData.Package_Info_In;
                    if (this.itemsLabel.length == 1) {
                      this.selectedLabel = this.itemsLabel[0].OptionsCode;
                      this.labelSelected();
                    }
                    if (!this.unitInfoSearched) {
                      let lpnDet = this.searchData.LPNorBox_Info.filter((obj) => obj.lpn_id == this.searchLPNData.HeaderInfo[0].lpn_id);
                      this.displayLPN = lpnDet[0].lpn;
                      this.lpnData = JSON.parse(JSON.stringify(this.searchLPNData.Dim_Info[0]));
                    } else {
                      this.lpnData.weight = this.searchLPNData.HeaderInfo[0].weight;
                      this.lpnData.box_h = this.searchLPNData.HeaderInfo[0].height;
                      this.lpnData.box_w = this.searchLPNData.HeaderInfo[0].width;
                      this.lpnData.box_l = this.searchLPNData.HeaderInfo[0].length;
                    }
                    if (this.searchData.Order_Info[0]?.require_rtn_wbn) {
                      this.showLPNInfo = true;
                    } else {
                      this.submitBoxDim();
                    }
                  } else {
                    LoaderDialog.visible = false;
                    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                    let Alert = {
                      type: "error",
                      isShow: true,
                      message: response.data.message,
                    };
                    this.$store.commit("ConfigModule/Alert", Alert);
                  }
                })
                .catch((error) => {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  this.hideVerifyDim = true;
                  if (this.searchData.Order_Info[0]?.carrier_short_name == "UPS") {
                    if (this.isJsonString(error?.response?.data?.message)) {
                      var errMsg = "";
                      let errUPSMsg = JSON.parse(error?.response?.data?.message);
                      errMsg = errUPSMsg?.response?.errors[0]?.message;
                      let Alert = {
                        type: "error",
                        isShow: true,
                        message: errMsg,
                      };
                      this.$store.commit("ConfigModule/Alert", Alert);
                    } else {
                      let Alert = {
                        type: "error",
                        isShow: true,
                        message: error?.response?.data?.message,
                      };
                      this.$store.commit("ConfigModule/Alert", Alert);
                    }
                  } else {
                    let Alert = {
                      type: "error",
                      isShow: true,
                      message: Utility.apiError(error),
                    };
                    this.$store.commit("ConfigModule/Alert", Alert);
                  }
                });
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              this.showLabels = false;
              this.showTracking = true;
              this.showFreight = true;
              this.showReturnSerial = false;
              this.flowDirection = "IN";
              this.confirmationDialog = true;
              this.closeDialog();
              if (this.searchData.Order_Info[0]?.require_rtn_wbn) {
                this.showLPNInfo = true;
              } else {
                this.submitBoxDim();
              }
              this.itemsLabel = [];
              this.itemsLabel = this.searchLPNData.Package_Info_In;
              if (this.itemsLabel.length == 1) {
                this.selectedLabel = this.itemsLabel[0].OptionsCode;
                this.labelSelected();
              }
              this.hideVerifyDim = false;
              if (!this.unitInfoSearched) {
                let lpnDet = this.searchData.LPNorBox_Info.filter((obj) => obj.lpn_id == this.searchLPNData.HeaderInfo[0].lpn_id);
                this.displayLPN = lpnDet[0].lpn;
                this.lpnData = JSON.parse(JSON.stringify(this.searchLPNData.Dim_Info[0]));
              } else {
                this.lpnData.weight = this.searchLPNData.HeaderInfo[0].weight;
                this.lpnData.box_h = this.searchLPNData.HeaderInfo[0].height;
                this.lpnData.box_w = this.searchLPNData.HeaderInfo[0].width;
                this.lpnData.box_l = this.searchLPNData.HeaderInfo[0].length;
              }
              if (this.searchData.Order_Info[0]?.require_rtn_wbn) {
                this.showSubmit = true;
                this.disableSubmitBtn = true;
              } else {
                this.showSubmit = false;
                this.disableSubmitBtn = false;
              }
            }
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.showLabels = false;
            this.showTracking = true;
            this.showFreight = true;
            this.showReturnSerial = false;
            if (this.searchData.Order_Info[0]?.require_rtn_wbn) {
              this.showSubmit = true;
              this.disableSubmitBtn = true;
            } else {
              this.showSubmit = false;
              this.disableSubmitBtn = false;
            }
          }
        }
      } else {
        if (this.$refs.packagingAddForm.validate()) {
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          this.hideVerifyDim = false;
          this.showReturnSerial = false;
          this.returnLPNData = this.lpnData;
          if (this.selectedLabel !== null || this.selectedLabel !== undefined || this.selectedLabel !== "") {
            if (this.selectedLabel !== "Other") {
              this.lpnData.box_l = 0;
              this.lpnData.box_w = 0;
              this.lpnData.box_h = 0;
              this.returnLPNData.box_l = 0;
              this.returnLPNData.box_w = 0;
              this.returnLPNData.box_h = 0;
            }
            this.selPackageIn = "";
            this.selPackageIn = this.selectedLabel;
          }
          if (this.searchData.Order_Info[0]?.require_rtn_wbn) {
            if (this.searchData.Order_Info[0]?.getWS_IB_WBN) {
              if (
                this.searchData.Order_Info[0]?.carrier_short_name_rtn == "UPS" ||
                this.searchData.Order_Info[0]?.carrier_short_name_rtn == "FedEx"
              ) {
                if (!this.unitInfoSearched) {
                  this.boxKey = parseInt(this.searchLPNData.HeaderInfo[0].lpn_id == "" ? 0 : this.searchLPNData.HeaderInfo[0].lpn_id);
                } else {
                  this.boxKey = parseInt(this.locToShipBoxId);
                }
                let obj = {
                  po_so: this.searchData.Order_Info[0]?.ordertype,
                  userId: parseInt(this.userId),
                  ord_key: this.searchData.Order_Info[0]?.order_key,
                  box_key: this.boxKey,
                  weight: this.lpnData.weight,
                  length: this.lpnData.box_l,
                  width: this.lpnData.box_w,
                  height: this.lpnData.box_h,
                  carrierCode: this.searchData.Order_Info[0]?.carrier_short_name_rtn,
                  direction: "IN",
                  lbl_size: this.secondaryLabelSize,
                  printer_brand: this.secondaryPrinterType,
                  packaging_code: this.selectedLabel,
                };
                this.axios
                  .post("sh/shipping_initiate", obj)
                  .then((response) => {
                    if (response.status == 200 || response.status == 204) {
                      let responseData = JSON.parse(response.data.body.message);
                      this.shippingVerifyDataIn = [];
                      this.shippingVerifyDataIn = responseData;
                      this.hideVerifyDim = false;
                      this.showLabels = true;
                      this.showTracking = false;
                      this.showFreight = false;
                      this.showReturnSerial = false;
                      this.showSubmit = false;
                      this.disableSubmitBtn = true;
                      LoaderDialog.visible = false;
                      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                      this.submitBoxDim();
                    } else {
                      LoaderDialog.visible = false;
                      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                      let Alert = {
                        type: "error",
                        isShow: true,
                        message: response.data.message,
                      };
                      this.$store.commit("ConfigModule/Alert", Alert);
                    }
                  })
                  .catch((error) => {
                    LoaderDialog.visible = false;
                    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                    this.hideVerifyDim = true;
                    if (this.searchData.Order_Info[0]?.carrier_short_name == "UPS") {
                      if (this.isJsonString(error?.response?.data?.message)) {
                        var errMsg = "";
                        let errUPSMsg = JSON.parse(error?.response?.data?.message);
                        errMsg = errUPSMsg?.response?.errors[0]?.message;
                        let Alert = {
                          type: "error",
                          isShow: true,
                          message: errMsg,
                        };
                        this.$store.commit("ConfigModule/Alert", Alert);
                      } else {
                        let Alert = {
                          type: "error",
                          isShow: true,
                          message: error?.response?.data?.message,
                        };
                        this.$store.commit("ConfigModule/Alert", Alert);
                      }
                    } else {
                      let Alert = {
                        type: "error",
                        isShow: true,
                        message: error?.response?.data?.message,
                      };
                      this.$store.commit("ConfigModule/Alert", Alert);
                    }
                  });
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                this.showLabels = false;
                this.showTracking = false;
                this.showReturnSerial = true;
                if (this.searchData.Order_Info[0]?.require_rtn_wbn) {
                  this.showSubmit = true;
                  this.disableSubmitBtn = true;
                } else {
                  this.showSubmit = false;
                  this.disableSubmitBtn = false;
                }
              }
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              this.showLabels = false;
              this.showTracking = false;
              this.showReturnSerial = true;
              this.showSubmit = true;
              this.disableSubmitBtn = true;
            }
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.submitBoxDim();
          }
        }
      }
    },
    //Checking whether it is a string or not
    isJsonString(value) {
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    },
    //Tracking values validation
    async validateTracking() {
      if (this.$refs.trackingWaybillValidateForm.validate()) {
        let obj = {
          lpn: this.searchLPNData.HeaderInfo[0].lpn_id,
          pass: 3,
          ordertype: this.searchData.Order_Info[0]?.ordertype,
          order_key: this.searchData.Order_Info[0]?.order_key,
          UserId: parseInt(this.userId),
          waybill: this.tracking,
          return_wbn: this.showReturnSerial,
          lbl_size: this.secondaryLabelSize,
          printer_type: this.secondaryPrinterType,
        };
        let res = await shippingService.getSearchShipFulData("post", obj, false);
        if (res) {
          this.showFreight = false;
          this.freight = 0;
          this.submitFreight = 0;
          this.disableTrackingWaybill = true;
          this.showFreight = false;
          this.showReturnSerial = false;
          this.hideVerifyDim = false;
          this.flowDirection = "IN";
          this.confirmationDialog = true;
          this.closeDialog();
          if (this.searchData.Order_Info[0]?.require_rtn_wbn) {
            this.showLPNInfo = true;
          } else {
            this.submitBoxDim();
          }
          this.itemsLabel = [];
          this.itemsLabel = this.searchLPNData.Package_Info_In;
          if (this.itemsLabel.length == 1) {
            this.selectedLabel = this.itemsLabel[0].OptionsCode;
            this.labelSelected();
          }
          if (!this.unitInfoSearched) {
            let lpnDet = this.searchData.LPNorBox_Info.filter((obj) => obj.lpn_id == this.searchLPNData.HeaderInfo[0].lpn_id);
            this.displayLPN = lpnDet[0].lpn;
            this.lpnData = JSON.parse(JSON.stringify(this.searchLPNData.Dim_Info[0]));
          } else {
            this.lpnData.weight = this.searchLPNData.HeaderInfo[0].weight;
            this.lpnData.box_h = this.searchLPNData.HeaderInfo[0].height;
            this.lpnData.box_w = this.searchLPNData.HeaderInfo[0].width;
            this.lpnData.box_l = this.searchLPNData.HeaderInfo[0].length;
          }
        }
      }
    },
    //Return Waybill Tracking
    async retValidateTracking() {
      if (this.$refs.retTrackingWaybillValidateForm.validate()) {
        let obj = {
          lpn: this.searchLPNData.HeaderInfo[0].lpn_id,
          pass: 3,
          ordertype: this.searchData.Order_Info[0]?.ordertype,
          order_key: this.searchData.Order_Info[0]?.order_key,
          UserId: parseInt(this.userId),
          waybill: this.returnTracking,
          return_wbn: this.showReturnSerial,
          lbl_size: this.secondaryLabelSize,
          printer_type: this.secondaryPrinterType,
        };
        let res = await shippingService.getSearchShipFulData("post", obj, false);
        if (res) {
          this.showFreight = false;
          this.freight = 0;
          this.submitBoxDim();
        }
      }
    },
    //Submitting Box Dim details
    submitBoxDim() {
      if (EncryptUtility.localStorageDecrypt("defaultName") !== null && EncryptUtility.localStorageDecrypt("defaultName") !== undefined) {
        if (this.tracking == "" || this.tracking == null) {
          if (this.shippingVerifyData?.tracking_number !== undefined && this.shippingVerifyData.length !== 0) {
            this.submitWaybill = this.shippingVerifyData?.tracking_number;
          }
        } else {
          this.submitWaybill = this.tracking;
        }
        if (this.searchData.Order_Info[0]?.require_rtn_wbn) {
          if (this.returnTracking == "" || this.returnTracking == null) {
            if (this.shippingVerifyDataIn?.tracking_number !== undefined && this.shippingVerifyDataIn.length !== 0) {
              this.submitRtnWaybill = this.shippingVerifyDataIn?.tracking_number;
            }
          } else {
            this.submitRtnWaybill = this.returnTracking;
          }
        } else {
          this.returnLPNData.weight = 0;
          this.returnLPNData.box_l = 0;
          this.returnLPNData.box_w = 0;
          this.returnLPNData.box_h = 0;
        }
        var LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        if (this.searchData.Order_Info[0]?.lpn_active) {
          let obj = {
            order_key: this.searchData.Order_Info[0]?.order_key,
            lpn_key: parseInt(this.searchLPNData.HeaderInfo[0].lpn_id),
            waybill: this.submitWaybill,
            cusrtn_wbn: this.submitRtnWaybill,
            freight_out: parseFloat(this.submitFreight),
            user_key: parseInt(this.userId),
            weight: parseFloat(this.directLPNData.weight),
            length: parseFloat(this.directLPNData.box_l),
            width: parseFloat(this.directLPNData.box_w),
            height: parseFloat(this.directLPNData.box_h),
            weight_rtn: parseFloat(this.returnLPNData.weight),
            length_rtn: parseFloat(this.returnLPNData.box_l),
            width_rtn: parseFloat(this.returnLPNData.box_w),
            height_rtn: parseFloat(this.returnLPNData.box_h),
            order_type: this.searchData.Order_Info[0]?.ordertype,
            ship_ob_label: this.shippingVerifyData.length == 0 ? "" : this.shippingVerifyData?.label,
            ship_ib_label: this.shippingVerifyDataIn.length == 0 ? "" : this.shippingVerifyDataIn?.label,
            ship_high_label: this.shippingVerifyData?.ship_high_label == null ? "" : this.shippingVerifyData?.ship_high_label,
            printer_type: this.secondaryPrinterType,
            packageCode: this.selPackageOut,
            packageCode_rtn: this.selPackageIn,
          };
          this.axios
            .post("sh/shipping_lpn", obj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                let lpnData = JSON.parse(response.data.body.message);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                if (lpnData !== undefined) {
                  if (this.secondaryName !== "" && this.secondaryName !== undefined) {
                    if (lpnData.pdf_url !== "" && lpnData.pdf_url !== undefined) {
                      //Default Printer is consumed to print the PDF
                      //In the URL Base64 string in pdf_url
                      Utility.printZPLPDF(lpnData.pdf_url, EncryptUtility.localStorageDecrypt("defaultName"));
                    }
                    if (lpnData.final_label !== "" && lpnData.final_label !== undefined) {
                      PrinterDetails.printLabelData(lpnData.final_label, this.secondaryName);
                    }
                    // if (lpnData.ob_label !== "" && lpnData.ob_label !== undefined) {
                    //   PrinterDetails.printLabelData(lpnData.ob_label, this.secondaryName);
                    // }
                    // if (lpnData.ib_lebl !== "" && lpnData.ib_lebl !== undefined) {
                    //   PrinterDetails.printLabelData(lpnData.ib_lebl, this.secondaryName);
                    // }
                    // if (lpnData.ship_label !== "" && lpnData.ship_label !== undefined) {
                    //   PrinterDetails.printLabelData(lpnData.ship_label, this.secondaryName);
                    // }
                  }
                  if (lpnData.set_ui == 0 || lpnData.set_ui == 1) {
                    this.lpnSearched = true;
                    this.lpnText = "";
                  } else if (lpnData.set_ui == 2) {
                    this.resetFunction();
                    this.$router.go(-1);
                  }
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            });
        } else {
          let obj = {
            pass: 3,
            po_so: this.searchData.Order_Info[0]?.ordertype,
            user_key: parseInt(this.userId),
            ord_key: parseInt(this.searchData.Order_Info[0]?.order_key),
            unit: "",
            box_key: this.locToShipBoxId,
            rware_key: 0,
            binsJson: "",
            packslip: "",
            waybill: this.submitWaybill,
            cusrtn_wbn: this.submitRtnWaybill,
            weight: parseFloat(this.directLPNData.weight),
            length: parseFloat(this.directLPNData.box_l),
            width: parseFloat(this.directLPNData.box_w),
            height: parseFloat(this.directLPNData.box_h),
            weight_rtn: parseFloat(this.returnLPNData.weight),
            length_rtn: parseFloat(this.returnLPNData.box_l),
            width_rtn: parseFloat(this.returnLPNData.box_w),
            height_rtn: parseFloat(this.returnLPNData.box_h),
            freight_out: parseFloat(this.submitFreight),
            box_no: 0,
            line_id: 0,
            lpn: this.searchLPNData.HeaderInfo[0].lpn_id,
            ship_ob_label: this.shippingVerifyData.length == 0 ? "" : this.shippingVerifyData?.label,
            ship_ib_label: this.shippingVerifyDataIn.length == 0 ? "" : this.shippingVerifyDataIn?.label,
            ship_high_label: this.shippingVerifyData?.ship_high_label == null ? "" : this.shippingVerifyData?.ship_high_label,
            printer_type: this.secondaryPrinterType,
            getWS_OB_WBN: this.searchData.Order_Info[0]?.getWS_OB_WBN,
            packageCode: this.selPackageOut,
            packageCode_rtn: this.selPackageIn,
          };
          this.axios
            .post("sh/shipping_non_lpn", obj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                let nonLpnData = JSON.parse(response.data.body.message);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                if (nonLpnData !== undefined) {
                  if (this.secondaryName !== "" && this.secondaryName !== undefined) {
                    if (nonLpnData.pdf_url !== "" && nonLpnData.pdf_url !== undefined) {
                      //Default Printer is consumed to print the PDF
                      //In the URL Base64 string in pdf_url
                      Utility.printZPLPDF(nonLpnData.pdf_url, EncryptUtility.localStorageDecrypt("defaultName"));
                    }
                    if (nonLpnData.final_label !== "" && nonLpnData.final_label !== undefined) {
                      PrinterDetails.printLabelData(nonLpnData.final_label, this.secondaryName);
                    }
                    // if (nonLpnData.ob_label !== "" && nonLpnData.ob_label !== undefined) {
                    //   PrinterDetails.printLabelData(nonLpnData.ob_label, this.secondaryName);
                    // }
                    // if (nonLpnData.ib_lebl !== "" && nonLpnData.ib_lebl !== undefined) {
                    //   PrinterDetails.printLabelData(nonLpnData.ib_lebl, this.secondaryName);
                    // }
                    // if (nonLpnData.ship_label !== "" && nonLpnData.ship_label !== undefined) {
                    //   PrinterDetails.printLabelData(nonLpnData.ship_label, this.secondaryName);
                    // }
                  }
                  if (nonLpnData.set_ui == 0 || nonLpnData.set_ui == 1) {
                    this.disableUnitIdentifier = true;
                    this.unitText = "";
                    this.onSearchClick(this.search);
                  } else if (nonLpnData.set_ui == 2) {
                    this.resetFunction();
                    this.$router.go(-1);
                  }
                }
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            });
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Default Printer is not configured, please configure printer.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Close of popups
    closeDialog() {
      if (this.locationToShipDialog) {
        this.unitData = [];
        this.warehouseDisabled = false;
        this.locationDisabled = true;
        this.disableSubmitLocToShip = true;
        this.selectedWarehouse = "";
        this.selectedLocation = "";
        this.totalOpenQty = 0;
        this.binData = [];
        this.totalShipped = 0;
        this.locationToShipDialog = false;
        this.shipQuantity = 0;
        if (this.unitInfoData.length == 0) {
          this.disableUnitIdentifier = false;
        } else {
          this.unitText = "";
          this.disableUnitIdentifier = true;
        }
        this.$refs.locationForm.resetValidation();
        this.$refs.unitSearchForm.resetValidation();
      } else if (this.confirmationDialog) {
        this.selectedLabel = "";
        this.selectedPackage = [];
        this.showOtherSelected = false;
        this.hideVerifyDim = true;
        this.showTracking = false;
        this.showLabels = false;
        this.showFreight = false;
        this.freight = 0;
        this.showSubmit = false;
        this.showLPNInfo = false;
        this.confirmationDialog = false;
        this.lpnSearched = false;
        this.disableReturnWaybill = false;
        this.disableTrackingWaybill = false;
        this.$refs.packagingAddForm.resetValidation();
        if (this.showTracking) {
          this.$refs.trackingWaybillValidateForm.resetValidation();
        }
      }
    },
    //Printer Configured
    printerDetails(labelPrinter) {
      if (!labelPrinter) {
        this.secondaryLabelSize = EncryptUtility.localStorageDecrypt("secondaryLabelSize");
        this.secondaryPrinterType = EncryptUtility.localStorageDecrypt("secondaryPrinterType");
        this.secondaryName = EncryptUtility.localStorageDecrypt("secondaryName");
        this.lpnSearched = false;
        this.printerAvlbl = true;
        this.printerConfigured = false;
        this.disableUnitIdentifier = false;
      } else {
        this.lpnSearched = true;
        this.printerAvlbl = false;
        this.printerConfigured = true;
        this.disableUnitIdentifier = true;
      }
    },
    //Validation firing using debounce method
    async inputValTracking() {
      if (this.tracking?.length > 2) {
        this.debouncedValidateTracking();
      }
    },
    //Validation firing using debounce method
    async inputReturnValTracking() {
      if (this.returnTracking?.length > 2) {
        this.debouncedRetValidateTracking();
      }
    },
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
    addressComp,
  },
};
