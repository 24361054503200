<template>
  <div>
    <v-row justify="center pt-3">
      <v-col cols="12" sm="12" md="12" lg="12" class="pa-2 pb-0">
        <!-- row 2 -->
        <v-form ref="AddressForm" lazy-validation @submit.prevent="submit">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder v-model="tempAddressObj.title" class="ma-0"
                label="Title" outlined clearable dense>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" :rules="required" persistent-placeholder v-model="tempAddressObj.last"
                class="ma-0" label="Name" outlined clearable dense>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder v-model="tempAddressObj.store" class="ma-0"
                label="Store/Site/Id" outlined clearable dense>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" :rules="required" persistent-placeholder
                v-model="tempAddressObj.company" class="ma-0" label="Company Name" outlined clearable dense>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Row 3 -->
          <v-row>
            <!-- Adddress 1 -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" :rules="required" persistent-placeholder
                v-model="tempAddressObj.address1" class="ma-0" label="Address1" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- Address 2 -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" v-model="tempAddressObj.address2" persistent-placeholder class="ma-0"
                label="Address2" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- City -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" :rules="required" persistent-placeholder v-model="tempAddressObj.city"
                label="City" class="ma-0" outlined clearable dense>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Row 4 -->
          <v-row>
            <!-- State -->
            <v-col v-if="showStateDrop" cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-autocomplete color="primarytext" :rules="required" persistent-placeholder
                v-model="tempAddressObj.state" class="ma-0" :items="stateData" item-text="States" label="State" outlined
                item-value="StateKey" dense>
              </v-autocomplete>
            </v-col>
            <!-- State if dropdown is empty -->
            <v-col v-else cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder :rules="stateRule"
                v-model="tempAddressObj.state" class="ma-0" label="State" name="State" outlined clearable dense
                @input="tempAddressObj.state = tempAddressObj.state?.toUpperCase()">
              </v-text-field>
            </v-col>
            <!-- zip -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder :rules="required" v-model="tempAddressObj.zip"
                label="Zip Code" outlined class="ma-0" clearable dense>
              </v-text-field>
            </v-col>
            <!-- COuntry -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-autocomplete :menu-props="{ maxHeight: 150 }" color="primarytext" persistent-placeholder
                :rules="required" attach outlined v-model="tempAddressObj.country_id" :items="countryData"
                item-text="country" item-value="country_id" label="Country" dense class="ma-0"
                @change="countrySelected()">
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- Row 5 -->
          <v-row>
            <!-- phone11 1 -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder
                :rules="tempAddressObj.phone1?.length > 0 || tempAddressObj.cell?.length > 0 ? [] : required" v-model="tempAddressObj.phone1"
                class="ma-0" label="Phone1" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- phone1 2 -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder v-model="tempAddressObj.phone2" class="ma-0"
                label="Phone2" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- Fax -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder v-model="tempAddressObj.fax" class="ma-0"
                label="Fax" outlined clearable dense>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Row 6 -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder
                :rules="tempAddressObj.phone1?.length > 0 || tempAddressObj.cell?.length > 0 ? [] : required" v-model="tempAddressObj.cell"
                class="ma-0" label="Cell" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- Intaddr -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder :rules="intaddrRule"
                v-model="tempAddressObj.intaddr" class="ma-0" label="Intaddr" outlined clearable dense>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- ROw 7 -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-autocomplete :menu-props="{ maxHeight: 150 }" color="primarytext" persistent-placeholder
                :rules="required" outlined v-model="tempAddressObj.slevel_key" :items="outboundList"
                item-text="sl_description" item-value="sl_id" label="Outbound Carrier/Service Level:" dense
                class="ma-0" @change="serviceLvlChange()">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-checkbox v-model="tempAddressObj.sig_required" label="Signature Required" class="pr-3 pl-3 pb-3 ma-0 d-inline-block"
                color="primary" hide-details :disabled="disableSign"></v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <!-- Saturday Delivery -->
              <v-checkbox v-model="tempAddressObj.sat_delivery" label="Saturday Delivery" class="pr-3 pl-3 pb-3 ma-0 d-inline-block"
                color="primary" hide-details :disabled="disableSatDelivery"></v-checkbox>
            </v-col>
          </v-row>
          <v-divider class="pt-5 pb-2"></v-divider>
          <v-row>
            <!-- submit -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-btn @click="submitAddress(compName)" color="primarytext" outlined class="mx-auto btn-style"
                elevation="1">
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import EncryptUtility from '../utility/js/encrypt-utility';
import commonApiService from '../common/js/common-api-service';
export default {
  name: 'Address',
  props: {
    compName: String,
    jsonEdit: String,
  },
  data() {
    return {
      max10Rule: [
        (v) => !!v || 'Field is required',
        (v) =>
          (v || '').length <= 10 ||
          'Max Length must be 10 characters or less',
      ],
      stateRule: [(v) => !!v || 'Field is required',
      (v) =>
        /^[a-zA-Z0-9 ]+$/.test(v) ||
        'special character are not allowed'
      ],
      required: [(v) => !!v || 'Field is required'],
      intaddrRule: [(v) =>
        /.+@.+\..+/.test(v) ||
        'Enter valid Intaddr'],
      sameAsCust: false,
      sameAsReturn: false,
      sameAsShip: false,
      showAddFreature: false,
      disableValidate: true,
      disableValidateBtn: false,
      outboundList: [],
      tempAddressObj: {
        store: "",
        fax: "",
        phone2: "",
        Name: "",
        phone1: "",
        intaddr: "",
        company: "",
        country_id: "",
        state: "",
        last: "",
        first: "",
        title: "",
        address2: "",
        address1: "",
        city: "",
        zip: "",
        slevel_key: "",
        cell: "",
        sat_delivery: false,
        sig_required: false,
      },
      countryData: [],
      stateData: "",
      userId: EncryptUtility.localStorageDecrypt('userID'),
      showStateDrop: false,
      disableSign: false,
      disableSatDelivery: false,
    }
  },
  async created() {
    this.route = this.$route.path.replace('/', '');
    this.countryData = this.jsonEdit?.Country_OutInfo;
    this.outboundList = this.jsonEdit?.SL_OutInfo
    this.serviceLvlChange();
    this.tempAddressObj = JSON.parse(JSON.stringify(this.jsonEdit.Order_Info[0]))
    this.tempAddressObj.last = this.tempAddressObj.first + " " + this.tempAddressObj.last;
    this.countrySelected();
  },
  mounted() { },
  destroyed() {
    this.clearAddress();
  },
  methods: {
    //To get the data of Dropdowns
    async submitAddress(name) {
      if (this.$refs.AddressForm.validate()) {
        if (name == 'updateCustAddress') {
          let tempObj = JSON.parse(JSON.stringify(this.tempAddressObj))
          this.$emit('updatedAPI', tempObj, "updateCustAddress");
        }
        else if (name == 'updateCustAddressPO') {
          let tempObj = JSON.parse(JSON.stringify(this.tempAddressObj))
          this.$emit('updatedAPI', tempObj, "updateCustAddress");
        }
        this.$refs.AddressForm.resetValidation()
        this.$refs.AddressForm.reset()
        this.clearAddress()
      }
    },
    //Clearing Address fields
    clearAddress() {
      this.tempAddressObj = {
        store: "",
        fax: "",
        phone2: "",
        Name: "",
        phone1: "",
        intaddr: "",
        company: "",
        country: "",
        state: "",
        last: "",
        first: "",
        title: "",
        address2: "",
        address1: "",
        city: "",
        zip: "",
        sat_delivery: false,
        sig_required: false,
      }
    },
    //Country Selected
    async countrySelected() {
      let data1 = await commonApiService.countryListNew(this.userId, this.tempAddressObj.country, 2, "get");
      this.stateData = data1.StateInfo;
      if (this.stateData.length !== 0) {
        this.showStateDrop = true;
      } else {
        this.showStateDrop = false;
      }
    },
    //Service Level change
    serviceLvlChange(){
      let carrier = this.outboundList.find((obj)=>obj.sl_id == this.tempAddressObj.slevel_key);
      this.disableSign = carrier.sig_required == 0 ? true: false;
      if(this.disableSign){
        this.tempAddressObj.sig_required = false;
      }      
      this.disableSatDelivery = carrier.sat_delivery == 0 ? true: false;
      if(this.disableSatDelivery){
        this.tempAddressObj.sat_delivery = false;
      }
    },
  }
}
</script>
<style scoped>
.v-autocomplete--content {
  max-height: 100px !important;
}
</style>